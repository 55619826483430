import { endpoints } from '../common/endpoints';
import { OnlineMeetingFormData } from '../types';

export const onlineMeetingSignUp = async (
  data: OnlineMeetingFormData,
): Promise<{ status: string }> => {
  const res = await fetch(
    `https://stmladonca.execute-api.eu-central-1.amazonaws.com/api${endpoints.tutorial}`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      body: JSON.stringify({ ...data, team_name: '1' }),
    },
  );

  if (!res.ok) {
    const error = await res.json();

    return Promise.reject(error || res.statusText);
  }

  return res.json();
};
