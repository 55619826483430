import { LogoutIcon, MenuIcon, QuestionMarkCircleIcon, XIcon } from '@heroicons/react/outline';
import { Popover, Transition } from '@headlessui/react';
import { Fragment, useCallback } from 'react';
import { useQueryClient } from 'react-query';
import clsx from 'clsx';
import CeLogo from '../../images/logo-ce.jpg';
import MeLogo from '../../images/logo-me.png';
import logo from '../../logo.svg';
import { LogoLink } from './LogoLink';
import {
  isContestEnded,
  isRegistrationEnded,
  isRegistrationStarted,
  isTutorialRegistrationStarted,
} from '../../utils';
import { useAuth } from '../../store';
import { useUserQuery } from '../../queries/auth-queries';
import { getLocalStorage, removeCookie, setLocalStorage } from '../../utils/cookies';
import { CookieKeys } from '../../types';
import { Navbar } from '../Navbar';
import { AppRoutes } from '../../types/routes';
import { endpoints } from '../../common/endpoints';

type Partner = {
  id: number;
  src: string;
  alt: string;
  url: string;
};

const PARTNERS: Partner[] = [
  {
    id: 1,
    src: CeLogo,
    alt: 'Comisia Europeană logo',
    url: 'https://romania.representation.ec.europa.eu/index_ro',
  },
  {
    id: 2,
    src: MeLogo,
    alt: 'Ministerul Educației logo',
    url: 'https://www.edu.ro/',
  },
];

export const Header = () => {
  const { accessToken, deAuthenticate } = useAuth();

  const { data } = useUserQuery();
  const queryClient = useQueryClient();

  const isCreateEnabled = !accessToken && isRegistrationStarted();
  const isLoginEnabled =
    !accessToken && (isRegistrationStarted() || isRegistrationEnded()) && !isContestEnded();
  const isUserLoggedIn = accessToken && data;

  const onLogoutHandler = useCallback(() => {
    removeCookie(CookieKeys.Token);
    deAuthenticate();
    queryClient.clear();
  }, [deAuthenticate, queryClient]);

  return (
    <Popover className="relative bg-white">
      <nav className="border-b-2 border-gray-100" style={{ position: 'relative' }}>
        {process.env.REACT_APP_DEMO === 'true' && (
          <div className="absolute p-4 bg-slate-200">
            <label
              htmlFor="registration-start-date"
              className="block text-sm font-medium text-gray-700"
            >
              Data de începere înregistrare
              <input
                className={clsx('form-field')}
                defaultValue={getLocalStorage('registration-start-date')}
                id="registration-start-date"
                name="registration-start-date"
                type="datetime-local"
                onChange={(e) => {
                  setLocalStorage('registration-start-date', e.target.value);
                }}
              />
            </label>
            <label
              htmlFor="registration-end-date"
              className="block text-sm font-medium text-gray-700"
            >
              Data de sfârșit înregistrare
              <input
                className={clsx('form-field')}
                defaultValue={getLocalStorage('registration-end-date')}
                id="registration-end-date"
                name="registration-end-date"
                type="datetime-local"
                onChange={(e) => {
                  setLocalStorage('registration-end-date', e.target.value);
                }}
              />
            </label>
            <label htmlFor="contest-date" className="block text-sm font-medium text-gray-700">
              Data de începere concurs
              <input
                className={clsx('form-field')}
                defaultValue={getLocalStorage('contest-date')}
                id="contest-date"
                name="contest-date"
                type="datetime-local"
                onChange={(e) => {
                  setLocalStorage('contest-date', e.target.value);
                }}
              />
            </label>

            <button
              type="button"
              className="primary-button"
              onClick={() => {
                setLocalStorage('registration-start-date', '');
                setLocalStorage('registration-end-date', '');
                setLocalStorage('contest-date', '');
              }}
            >
              Resetare valori
            </button>
          </div>
        )}
        <div className="layout-container flex justify-between items-center py-3 gap-2">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <a href={AppRoutes.Home}>
              <img className="h-4 w-auto sm:h-7" src={logo} alt="Euro Quiz Logo" />
            </a>
          </div>
          <div className="md:hidden ml-auto">
            <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary">
              <span className="sr-only">Open menu</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>

          <Navbar hidden />

          <div className="flex items-center justify-end md:flex-1 lg:w-0 gap-2">
            {isLoginEnabled && (
              <a
                href={AppRoutes.Login}
                className={`${
                  isRegistrationEnded() ? 'primary-button' : 'secondary-button'
                } hidden md:inline-flex`}
              >
                Autentificare
              </a>
            )}

            {isCreateEnabled && (
              <a href={AppRoutes.Register} className="hidden md:inline-flex primary-button">
                Creează cont
              </a>
            )}

            {/* {isTutorialRegistrationStarted() && (
              <a href={AppRoutes.Tutorial} className="hidden md:inline-flex primary-button">
                Înscrie-te la întâlnirile online de informare
              </a>
            )} */}

            {isUserLoggedIn && (
              <div className="hidden md:inline-flex items-center gap-6">
                <div>
                  Bine ai venit,{' '}
                  <a href={endpoints.user} className="text-primary">
                    {data?.name}
                  </a>
                </div>
                <button
                  type="button"
                  className="secondary-button text-gray-400 hover:bg-red-200 hover:text-red-500"
                  onClick={onLogoutHandler}
                >
                  <LogoutIcon className="h-6 w-6" />
                </button>
              </div>
            )}
            <a
              href="https://romania.representation.ec.europa.eu/euro-quiz_ro"
              rel="noreferrer"
              target="_blank"
              className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-primary hover:bg-gray-100 "
            >
              <QuestionMarkCircleIcon className="h-6 w-6" />
            </a>
          </div>
        </div>
      </nav>
      <div className="layout-container flex flex-wrap gap-3 items-center justify-end py-2">
        {PARTNERS.map(({ id, alt, src, url }) => (
          <LogoLink key={id} alt={alt} logo={src} url={url} />
        ))}
      </div>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
        >
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
            <div className="pt-5 pb-6 px-5">
              <div className="flex items-center justify-between">
                <div>
                  <img className="h-4 w-auto" src={logo} alt="Euro Quiz Logo" />
                </div>
                <div className="-mr-2">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Close menu</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
            </div>

            <Navbar />

            <div className="py-6 px-5 space-y-6">
              {isLoginEnabled && (
                <a
                  href={AppRoutes.Login}
                  className={`${isRegistrationEnded() ? 'primary-button' : 'secondary-button'}`}
                >
                  Autentificare
                </a>
              )}

              {isCreateEnabled && (
                <a href={AppRoutes.Register} className="primary-button">
                  Creează cont
                </a>
              )}

              {isTutorialRegistrationStarted() && (
                <a href={AppRoutes.Tutorial} className="primary-button">
                  Înscrie-te la întâlnirile online de informare
                </a>
              )}

              {isUserLoggedIn ? (
                <>
                  <div className="text-center py-3">
                    Bine ai venit, <span className="text-primary">{data?.name}</span>
                  </div>
                  <button
                    type="button"
                    className="secondary-button text-gray-400 hover:bg-red-200 hover:text-red-500 w-full"
                    onClick={onLogoutHandler}
                  >
                    Deconectare
                  </button>
                </>
              ) : (
                isCreateEnabled && (
                  <p className="mt-6 text-center text-base font-medium text-gray-500">
                    Aveti deja cont ?{' '}
                    <a href={AppRoutes.Login} className="text-primary hover:text-primary-dark">
                      Autentificare
                    </a>
                  </p>
                )
              )}
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};
