import * as yup from 'yup';
import { SchemaOf } from 'yup';
import { Grade, OnlineMeetingFormData } from '../types';
import { phoneNumberRegex } from '../utils';

export const onlineMeetingFormSchema: SchemaOf<OnlineMeetingFormData> = yup.object().shape({
  school: yup.string().required('Vă rugăm introduceți numele unității de învățământ'),
  locality: yup.string().required('Vă rugăm introduceți localitatea unității de învățământ'),
  county_id: yup.string().required('Vă rugăm introduceți județul unității de învățământ'),
  name: yup.string().required('Vă rugăm introduceți numele coordonatorului'),
  phone: yup
    .string()
    .required('Vă rugam introduceți numărul de telefon')
    .matches(phoneNumberRegex, 'Numărul de telefon în format invalid'),
  email: yup
    .string()
    .email('Vă rugăm introduceți o adresă de e-mail validă')
    .required('Vă rugăm introduceți adresa de e-mail'),
  student_count: yup.string(),
  grade: yup.mixed<Grade>().oneOf(Object.values(Grade)),
  rules: yup
    .boolean()
    .required('Vă rugam să acceptați regulile')
    .oneOf(
      [true],
      'Pentru a merge mai departe trebuie să vă dați acordul cu privire la regulamentul campaniei',
    ),
  statut: yup.mixed().oneOf(['student', 'profesor']).required('Vă rugăm selectați statutul'),
  gdpr: yup
    .boolean()
    .required()
    .oneOf(
      [true],
      'Pentru a merge mai departe trebuie să vă dați acordul cu privire la procesarea datelor cu caracter personal',
    ),
});
