import { format } from 'date-fns';
import { ro } from 'date-fns/locale';
import {
  CONTEST_DURATION,
  CONTEST_DATE,
  REGISTRATION_END_DATE,
  REGISTRATION_START_DATE,
  TUTORIAL_REGISTRATION_END_DATE,
  TUTORIAL_REGISTRATION_START_DATE,
} from '../common/constants';
import { getLocalStorage } from './cookies';

export const phoneNumberRegex = /^[0-9]{10}$/;

const monthNames = [
  'Ianuarie',
  'Februarie',
  'Martie',
  'Aprilie',
  'Mai',
  'Iunie',
  'Iulie',
  'August',
  'Septembrie',
  'Octombrie',
  'Noiembrie',
  'Decembrie',
];

const getMonthName = (index: number) => monthNames[index];

export const formatTime = (time?: number) => {
  if (!time) return '00';

  return time < 10 ? `0${time}` : time.toString();
};

export const isTutorialRegistrationStarted = () => {
  const today = Date.now();
  return (
    today >= TUTORIAL_REGISTRATION_START_DATE.getTime() &&
    today <= TUTORIAL_REGISTRATION_END_DATE.getTime()
  );
};

export const isTutorialRegistrationEnded = () => {
  const today = Date.now();
  return today > TUTORIAL_REGISTRATION_END_DATE.getTime();
};

export const isTutorialInProgress = () => {
  const today = Date.now();
  return (
    today >= new Date(2025, 2, 24, 0, 0, 0).getTime() &&
    today <= new Date(2025, 3, 2, 23, 59, 59).getTime()
  );
};

export const isTutorialEnded = () => {
  const today = Date.now();
  return today >= new Date(2023, 2, 16).getTime();
};

export const isRegistrationStarted = () => {
  const today = Date.now();

  const demoStartTime = getLocalStorage('registration-start-date');
  const startDate =
    demoStartTime && process.env.REACT_APP_DEMO === 'true'
      ? new Date(demoStartTime).getTime()
      : REGISTRATION_START_DATE.getTime();
  const demoEndTime = getLocalStorage('registration-end-date');
  const endDate =
    demoEndTime && process.env.REACT_APP_DEMO === 'true'
      ? new Date(demoEndTime).getTime()
      : REGISTRATION_END_DATE.getTime();

  return today >= startDate && today <= endDate;
};

export const isRegistrationEnded = () => {
  const today = Date.now();

  const demoTime = getLocalStorage('registration-end-date');
  const endDate =
    demoTime && process.env.REACT_APP_DEMO === 'true'
      ? new Date(demoTime).getTime()
      : REGISTRATION_END_DATE.getTime();

  return today > endDate;
};

export const isContestStarted = () => {
  const today = Date.now();

  const demoTime = getLocalStorage('contest-date');
  const contestDate =
    demoTime && process.env.REACT_APP_DEMO === 'true'
      ? new Date(demoTime).getTime()
      : CONTEST_DATE.getTime();

  return today >= contestDate && today < contestDate + CONTEST_DURATION;
};

export const isContestEnded = () => {
  const today = Date.now();

  const demoTime = getLocalStorage('contest-date');
  const contestDate =
    demoTime && process.env.REACT_APP_DEMO === 'true'
      ? new Date(demoTime).getTime()
      : CONTEST_DATE.getTime();

  return today > contestDate + CONTEST_DURATION;
};

export const getTutorialCountdownData = () => {
  if (!isTutorialRegistrationStarted() && !isTutorialRegistrationEnded()) {
    return {
      title: `Înscrierile la întâlnirile online de informare și pregătire încep în curând, ${format(
        TUTORIAL_REGISTRATION_START_DATE,
        'dd MMMM yyyy',
        {
          locale: ro,
        },
      )}`,
      time: TUTORIAL_REGISTRATION_START_DATE.getTime(),
    };
  }

  if (isTutorialRegistrationStarted()) {
    return {
      title: `Înscrierile la întâlnirile online de informare și pregătire sunt deschise. Termenul-limită de înscriere este ${format(
        TUTORIAL_REGISTRATION_END_DATE,
        'dd MMMM yyyy',
        {
          locale: ro,
        },
      )}`,
      time: TUTORIAL_REGISTRATION_END_DATE.getTime(),
    };
  }

  if (isTutorialRegistrationEnded()) {
    return {
      title: undefined,
      time: undefined,
    };
  }

  return {};
};

export const getCountdownData = () => {
  if (!isRegistrationStarted() && !isRegistrationEnded()) {
    const demoTime = getLocalStorage('registration-start-date');
    const startDate =
      demoTime && process.env.REACT_APP_DEMO === 'true'
        ? new Date(demoTime)
        : REGISTRATION_START_DATE;

    return {
      title: `Înscrierile la concurs încep în curând, ${format(startDate, 'dd MMMM yyyy', {
        locale: ro,
      })}`,
      time: startDate.getTime(),
    };
  }

  if (isRegistrationStarted()) {
    const demoTime = getLocalStorage('registration-end-date');
    const endDate =
      demoTime && process.env.REACT_APP_DEMO === 'true'
        ? new Date(demoTime)
        : REGISTRATION_END_DATE;

    return {
      title: `Înscrierile sunt deschise. Termenul-limită de înscriere este ${format(
        endDate,
        'dd MMMM yyyy',
        {
          locale: ro,
        },
      )}`,
      time: endDate.getTime(),
    };
  }

  if (isRegistrationEnded() && !isContestStarted() && !isContestEnded()) {
    const demoTime = getLocalStorage('contest-date');
    const contestDate =
      demoTime && process.env.REACT_APP_DEMO === 'true' ? new Date(demoTime) : CONTEST_DATE;

    const monthName = getMonthName(contestDate.getMonth());
    const formatedDate = `${contestDate.getDate()} ${monthName} ${contestDate.getFullYear()} ora ${contestDate.getHours()}:${formatTime(
      contestDate.getMinutes(),
    )}`;
    return {
      title: `Etapa județeană, ${formatedDate}`,
      time: contestDate.getTime(),
    };
  }

  if (isContestStarted()) {
    return {
      title: 'Etapa județeană este în desfășurare',
      time: undefined,
    };
  }

  if (isContestEnded()) {
    return {
      title: undefined,
      time: undefined,
    };
  }

  return {};
};

export const getContestCountdownData = () => {
  if (!isContestStarted() && !isContestEnded()) {
    const demoTime = getLocalStorage('contest-date');
    const contestDate =
      demoTime && process.env.REACT_APP_DEMO === 'true'
        ? new Date(demoTime).getTime()
        : CONTEST_DATE.getTime();

    return {
      title:
        'Concursul va începe în curând. Vă rugăm să vă autentificați cu cel puțin 30 minute înainte de concurs.',
      time: contestDate,
    };
  }

  if (isContestStarted() && !isContestEnded()) {
    return { title: 'Concursul este în desfășurare.', time: undefined };
  }

  if (isContestEnded()) {
    return {
      title: 'Concursul s-a încheiat.',
      time: undefined,
    };
  }

  return {};
};

export const getRemainingTime = (endTime: number) => {
  const now = Date.now();
  const remainingTime = endTime - now;

  if (remainingTime < 0) return { days: 0, hours: 0, minutes: 0, seconds: 0 };

  const days = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
  const hours = Math.floor((remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);

  return { days, hours, minutes, seconds };
};

export const formatWordPlural = (n: number, wordSingular: string, wordPlural: string) => {
  if (n === 1) {
    return wordSingular;
  }

  if (n < 20) {
    return `${n} ${wordPlural}`;
  }

  return `${n} de ${wordPlural}`;
};
