// import { useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
import { ContentWrapper } from '../../components';
import { RegistrationForm } from '../../components/RegistrationForm';
// import { isRegistrationStarted } from '../../utils';

export const RegistrationPage = () => {
  // const navigate = useNavigate();

  // useEffect(() => {
  //   if (!isRegistrationStarted()) {
  //     navigate('/', { replace: true });
  //   }
  // }, [navigate]);

  return (
    <ContentWrapper>
      <RegistrationForm />
    </ContentWrapper>
  );
};
