import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ContentWrapper } from '../../components';
import { OnlineMeetingRegistrationForm } from '../../components/OnlineMeetingRegistrationForm';
import { isTutorialRegistrationEnded, isTutorialRegistrationStarted } from '../../utils';

export const OnlineMeetingRegister = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (isTutorialRegistrationEnded() || !isTutorialRegistrationStarted()) {
      navigate('/', { replace: true });
    }
  }, [location.pathname, navigate]);

  return (
    <>
      <ContentWrapper>
        <div className="flex justify-center">
          <OnlineMeetingRegistrationForm />
        </div>
      </ContentWrapper>
      {/* {hasAlreadyRegistered && (
        <div className="flex p-4 rounded-md bg-green-50">
          <svg
            className="w-5 h-5 text-green-400 shrink-0"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
              clipRule="evenodd"
            />
          </svg>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-green-800">
              V-ați înscris la întâlnirea online din data de {registeredDate}.
            </h3>
            <div className="mt-2 text-sm text-green-700">
              Verificați adresa de e-mail pentru confirmare și link-ul de participare la întâlnire!
            </div>
          </div>
        </div>
      )} */}
    </>
  );
};
