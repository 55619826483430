import { UseMutationOptions, useMutation } from 'react-query';
import { onlineMeetingSignUp } from '../data';
import { OnlineMeetingFormData } from '../types';
import { ValidationError } from './auth-mutations';

type OnlineRegistrationMutationOptions = UseMutationOptions<
  { status: string },
  ValidationError,
  OnlineMeetingFormData
>;

export const useOnlineRegistrationMutation = (options?: OnlineRegistrationMutationOptions) => {
  return useMutation(onlineMeetingSignUp, { ...options });
};
