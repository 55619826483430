import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField } from '../TextField';
import { Grade, OnlineMeetingFormData } from '../../types';
import { Dropdown } from '../Dropdown';
import { Checkbox } from '../Checkbox';
import { useCountiesQuery } from '../../queries';
import { LoadingSpiner } from '../LoadingSpiner';
import { Alert } from '../Alert';
import { Radio } from '../Radio';
import { onlineMeetingFormSchema } from '../../validation/onlilne-meeting-form-schema';
import { useOnlineRegistrationMutation } from '../../queries/online-registration-mutation';

export const OnlineMeetingRegistrationForm = () => {
  const navigate = useNavigate();

  const {
    formState: { errors },
    handleSubmit,
    register,
    setError,
    watch,
  } = useForm<OnlineMeetingFormData & {}>({
    defaultValues: {
      student_count: '',
      statut: 'student',
      grade: Grade.Fifth,
    },
    mode: 'all',
    resolver: yupResolver(onlineMeetingFormSchema),
  });

  const { data: counties } = useCountiesQuery();

  const { mutate: tutorialSignUp, isLoading: isSigningUpForTutorial } =
    useOnlineRegistrationMutation();

  const [message, setMessage] = useState<string>();

  const status = watch('statut');

  const onSubmit: SubmitHandler<OnlineMeetingFormData> = (data) => {
    tutorialSignUp(
      {
        ...data,
        grade: data.statut === 'profesor' ? undefined : data.grade,
        student_count: data.statut === 'profesor' ? data.student_count : '0',
      },
      {
        onSuccess: () => {
          navigate('/');
        },
        onError: (error) => {
          if (error.errors) {
            Object.keys(error.errors).forEach((key: string) => {
              if (key === 'email') {
                setError(key, {
                  type: 'required',
                  message: 'Această adresă de email a fost folosită deja',
                });
              }
              if (key === 'phone') {
                setError(key, {
                  type: 'required',
                  message: 'Aceast număr de telefon a fost folosit deja',
                });
              }
            });
            return;
          }
          setMessage('Am întâmpinat o problemă. Vă rugăm verificați datele și încercați din nou.');
        },
      },
    );
  };

  return (
    <div className="mt-10 sm:mt-0">
      <div className="mt-5 md:mt-0 max-w-[800px] mx-auto ">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="shadow bg-gray-50 overflow-hidden sm:rounded-md">
            <div className="px-4 py-5 sm:p-6">
              <div className="text-2xl mb-4">
                Formular de înscriere la întâlnirile de pregătire online
              </div>
              <h3 className="text-lg">Unitate de învățământ</h3>
              <div className="grid grid-cols-8 gap-x-6">
                <div className="col-span-8 md:col-span-4">
                  <TextField
                    errors={errors.school}
                    label="Nume"
                    name="school"
                    placeholder="Școala gimnazială nr. 1"
                    register={register}
                    required
                  />
                </div>

                <div className="col-span-8 sm:col-span-4 md:col-span-2">
                  <TextField
                    errors={errors.locality}
                    label="Localitate"
                    name="locality"
                    register={register}
                    required
                  />
                </div>

                <div className="col-span-8 sm:col-span-4 md:col-span-2">
                  <Dropdown
                    defaultValue=""
                    errors={errors.county_id}
                    label="Județ"
                    required
                    {...register(`county_id`)}
                  >
                    <option disabled value="">
                      -- județul --
                    </option>
                    {counties?.map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </Dropdown>
                </div>
              </div>

              <div className="border-b border-slate-300 mb-4 w-full" />

              <h3 className="text-lg mb-2">Statut:</h3>
              <div className="grid grid-cols-4 gap-y-2">
                <div className="col-span-12">
                  <Radio {...register('statut')} label="Sunt elev" value="student" />
                </div>
                <div className="col-span-12">
                  <Radio {...register('statut')} label="Sunt profesor" value="profesor" />
                </div>
              </div>

              <div className="border-b border-slate-300 my-4 w-full" />

              <div className="grid grid-cols-8 gap-x-6">
                <div className="col-span-8 md:col-span-4">
                  <TextField
                    errors={errors.name}
                    label="Nume și prenume"
                    name="name"
                    register={register}
                    required
                  />
                </div>

                <div className="col-span-8 sm:col-span-3 md:col-span-2">
                  <TextField
                    errors={errors.phone}
                    label="Număr telefon"
                    name="phone"
                    placeholder="0712345678"
                    register={register}
                    required
                  />
                </div>

                <div className="col-span-8 sm:col-span-5 md:col-span-4">
                  <TextField
                    errors={errors.email}
                    label="Adresă e-mail"
                    name="email"
                    placeholder="coordonator@email.com"
                    register={register}
                    required
                  />
                </div>
                {status === 'profesor' && (
                  <div className="col-span-8 sm:col-span-5 md:col-span-4">
                    <TextField
                      errors={errors.student_count}
                      label="Cu câți elevi dorești să participi la întâlnirile de pregătire"
                      name="student_count"
                      register={register}
                      required
                      type="number"
                    />
                  </div>
                )}
              </div>

              {status === 'student' && (
                <>
                  <h3 className="text-lg mb-2">Clasa:</h3>
                  <div className="grid grid-cols-4 gap-y-2">
                    <div className="col-span-12">
                      <Radio {...register('grade')} label="a V-a" value="5" />
                    </div>
                    <div className="col-span-12">
                      <Radio {...register('grade')} label="a VI-a" value="6" />
                    </div>
                    <div className="col-span-12">
                      <Radio {...register('grade')} label="a VII-a" value="7" />
                    </div>
                    <div className="col-span-12">
                      <Radio {...register('grade')} label="a VIII-a" value="8" />
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className="px-4 py-3 sm:px-6">
              <Checkbox
                errors={errors.rules}
                label={
                  <>
                    Am citit și sunt de acord cu{' '}
                    <a
                      href="https://romania.representation.ec.europa.eu/euro-quiz_ro"
                      className="text-blue-600 hover:underline"
                      target="_blank"
                      rel="noreferrer"
                    >
                      regulamentul campaniei
                    </a>
                  </>
                }
                {...register('rules')}
                required={false}
              />

              <Checkbox
                errors={errors.gdpr}
                label={`Confirm că dețin acordurile pentru utilizarea imaginii și GDPR pentru elevii care participă în întâlnire și, la cerere, le pun la dispoziția organizatorului pentru desfășurarea concursului Euro Quiz, ediția ${new Date().getFullYear()}.`}
                {...register('gdpr')}
              />
              <div className="flex flex-col gap-4 sm:flex-row sm:items-center sm:justify-end">
                {message && (
                  <div className="sm:mr-auto">
                    <Alert message={message} type="error" />
                  </div>
                )}
                {isSigningUpForTutorial && <LoadingSpiner />}
                <button disabled={isSigningUpForTutorial} type="submit" className="primary-button">
                  Înscrie-te
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
