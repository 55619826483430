import { Counties } from '../types/counties';
import { endpoints } from '../common/endpoints';

export const getCounties = async (): Promise<Counties> => {
  const res = await fetch(
    `https://stmladonca.execute-api.eu-central-1.amazonaws.com/api${endpoints.counties}`,
  );

  if (!res.ok) {
    throw new Error(res.statusText);
  }

  const { data } = await res.json();

  return data;
};
